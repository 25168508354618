import React from 'react';

import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { Nav } from 'components/global/Nav';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Footer } from 'components/global/Footer';
import { FooterDisclaimer } from 'components/global/FooterDisclaimer';
import {
  LandingHeaderReskin,
  LANDING_HEADER_RESKIN_START_TRIAL_RX_CONTENT,
} from 'components/modules/LandingHeaderReskin';

const StartTrialRxPage = () => (
  <LandingPageWrapper>
    <SEO {...SEO_CORE_CONTENT} />
    <Nav />
    <LandingHeaderReskin {...LANDING_HEADER_RESKIN_START_TRIAL_RX_CONTENT} />
    <Footer disclaimer={<FooterDisclaimer />} />
  </LandingPageWrapper>
);

export default StartTrialRxPage;
